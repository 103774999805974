<template lang="">
    <div class="wrapper">
        <el-breadcrumb separator="/" class="el-breadcrumb">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/EnterpriseService' }">业务发展</el-breadcrumb-item>
            <el-breadcrumb-item >金融超市</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="concent">
            <!-- 融资详情 -->
            <section class="section1">
                <div class="chartView clearfix">
                    <div class="top clearfix">
                        <div class="title fl">融资详情</div>
                    </div>
                    <div class="center">
                        <dl class="clearfix">
                            <dt class="w100">贷款产品信息</dt>
                            <dd class="w20 ddbg">产品名称</dd>
                            <dd class="w80" style="color: #0E459C;font-weight: bold;">简式贷</dd>
                            <dd class="w20 ddbg">贷款额度</dd>
                            <dd class="w25">3000万元</dd>
                            <dd class="w20 ddbg">贷款期限</dd>
                            <dd class="w35">36个月</dd>
                            <dd class="w20 ddbg">贷款额度说明</dd>
                            <dd  class="w25">抵押、保证方式均可，最高30000万元</dd>
                            <dd class="w20 ddbg">适用对象</dd>
                            <dd class="w35">满足小微企业生产经营过程中周转、季节性、临时性的资金需求</dd>

                            <dt class="w100">贷款信息</dt>
                            <dd class="w20 ddbg">贷款企业</dd>
                            <dd class="w80" style="color: #0E459C;font-weight: bold;">温州********有限公司</dd>
                            <dd class="w20 ddbg">贷款编号</dd>
                            <dd class="w25">MDKF72854903249052</dd>
                            <dd class="w20 ddbg">贷款方式</dd>
                            <dd class="w35">保证</dd>
                            <dd class="w20 ddbg">贷款金额</dd>
                            <dd  class="w25">800.00万元</dd>
                            <dd class="w20 ddbg">贷款期限</dd>
                            <dd class="w35">12个月</dd>
                            <dd class="w20 ddbg">贷款资金用途</dd>
                            <dd class="w25">流动资金</dd>
                            <dd class="w20 ddbg">还款来源</dd>
                            <dd class="w35">经营收入</dd>
                            <dd class="w20 ddbg">处理状态</dd>
                            <dd  class="w25">对接中</dd>
                            <dd class="w20 ddbg">是否有抵押物</dd>
                            <dd class="w35">是</dd>
                            <dd class="w20 ddbg">其他要求</dd>
                            <dd class="w80">— —</dd>

                            <dt class="w100">平台受理信息</dt>
                            <dd class="w20 ddbg">受理人</dd>
                            <dd class="w25">中小企业平台管理员</dd>
                            <dd class="w20 ddbg">受理时间</dd>
                            <dd class="w35">2020-10-10</dd>
                            <dd class="w20 ddbg">受理方式</dd>
                            <dd  class="w25">网站</dd>
                            <dd class="w20 ddbg">处理状态</dd>
                            <dd class="w35">对接中</dd>
                            <dd class="w20 ddbg">处理结果</dd>
                            <dd class="w80">— —</dd>

                            <dt class="w100">意向银行信息</dt>
                            <dd class="w45">平阳县兴平融资担保有限公司</dd>
                            <dd class="w20 ddbg">银行审核状态</dd>
                            <dd class="w35">未审核</dd>
                            <dd class="w45">温州平阳涉企银行信贷测试企业1</dd>
                            <dd class="w20 ddbg">银行审核状态</dd>
                            <dd class="w35">审核通过</dd>

                            <dt class="w100">贷款发放信息</dt>
                            <dd class="w20 ddbg">贷款发放银行</dd>
                            <dd class="w25">测试机构14</dd>
                            <dd class="w20 ddbg">银行审核状态</dd>
                            <dd class="w35">审核通过</dd>
                            <dd class="w20 ddbg">银行审核意见</dd>
                            <dd class="w80">— —</dd>
                            <dd class="w20 ddbg">银行处理状态</dd>
                            <dd  class="w25">对接成功</dd>
                            <dd class="w20 ddbg">企业响应状态</dd>
                            <dd class="w35">已响应</dd>
                            <dd class="w20 ddbg">发放贷款金额</dd>
                            <dd class="w25">11万元</dd>
                            <dd class="w20 ddbg">发放贷款期限</dd>
                            <dd class="w35">12个月</dd>
                            <dd class="w20 ddbg">发放贷款日期</dd>
                            <dd  class="w80">2019-10-10</dd>
                        
                            
                        </dl>
                    </div>
                   
                </div>
            </section>
            
        </div>
    </div>
</template>
<script>
    // 
    export default {
        name: 'demandDetails',
        data() {
            return {

            };
        },
        methods: {
            goback() {
                this.$router.go(-1)
            },

        },
        mounted() {

        },
        created() {

        },
    };
</script>
<style lang="scss" scoped>
    *{
        box-sizing: border-box;
        font-size: 14px;
        color: #333;
    }
    .fl {
        float: left;
    }

    .fr {
        float: right;
    }

    .tc {
        text-align: center;
    }

    .clearfix::after {
        content: '';
        display: block;
        clear: both;
    }

    .wrapper {
        padding-left: 83px;
        padding-right: 83px;
        padding-bottom: 15px;
        margin: 0 auto;
        min-width: 1366px;

        section {
            margin-bottom: 16px;
        }

        .concent {
            .section1 {
                min-height: 168px;
            }

            .section2 {
                min-height: 291px;
            }

            .section3 {
                min-height: 291px;
            }
        }
    }

    

    .w100{
        width: 100%;
    }

    .w25{
        width: 25%;
    }

    .w20{
        width: 20%;
    }

    .w45{
        width: 45%;
    }

    .w80{
        width: 80%;
    }

    .w35{
        width: 35%;
    }
    dt,dd{
        height: 48px;
        padding: 17px 21px;
        float: left;
        border: 1px solid #DDDDDD;
        color: #333;
    }
    dt {
        background: #F3F3F5;
        color: #666;
    }

    .ddbg{
        background: #FAFAFA;
        color: #666666;
    }

    .dataOverview .right-box .list-item {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        font-size: 14px;
        color: #666666;
        margin-bottom: 16px;
    }

    .dataOverview .right-box .list-item:nth-last-of-type(1) {
        margin-bottom: 0;
    }

    .chartView {
        padding: 16px 20px 24px;
        background-color: #fff;
    }

    .chartView .top {
        padding-bottom: 15px;
        border-bottom: 1px solid #dddddd;
        margin-bottom: 16px;
    }

    .chartView .item-box {
        width: calc((100% - 40px) * 0.33333);
        height: 176px;
        border-radius: 0px 0px 4px 4px;
        background-image: linear-gradient(180deg, #f6faff 0%, #e9f4ff 100%);
        box-sizing: border-box;
        margin-bottom: 20px;
        margin-right: 20px;
    }

    .chartView .item-box:nth-child(3n + 1) {
        margin-right: 0;
    }

    .chartView .item-box:nth-child(n + 4) {
        margin-bottom: 0;
    }

    .chartView .item-box .item-top {
        border-top: 2px solid #4393f8;
        margin-bottom: 16px;
        position: relative;
    }

    .chartView .item-box .item-top .top-bg {
        height: 0px;
        width: 172px;
        border-top: 40px solid #4393f8;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -96px;
    }


    .chartView .center p {
        line-height: 30px;
    }

    .chartView .item-box .item-bottom {
        height: 40px;
        line-height: 40px;
        padding: 0 25px;
        margin-top: 22px;
        background: #cee4ff;
        border-radius: 0px 0px 4px 4px;
    }

    .chartView .top .time {
        font-size: 14px;
        color: #666666;
        text-align: right;
        line-height: 20px;
        font-weight: 600;
    }

    .chartView .top .img {
        width: 16px;
        height: 16px;
        margin-top: 10px;
        cursor: pointer;
    }

    .chartView .item-box .item-title {
        height: 40px;
        line-height: 40px;
        font-family: MicrosoftYaHei-Bold;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
        z-index: 1;
        position: relative;
    }

    .concent .title {
        font-family: 'MicrosoftYaHei-Bold';
        font-size: 26px;
        font-weight: 600;
        color: #333333;
        line-height: 36px;
        position: relative;
        padding-left: 14px;
    }

    .concent .title:before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 20px;
        background: #4393f8;
        border-radius: 3px;
        position: absolute;
        top: 10px;
        left: 0;
    }

 
</style>